<template>
    <v-row justify="center">
      <v-dialog
        class="request-duplicate-confirmation"
        v-model="dialog"
        max-width="1200"
        @input="checkDialogClose"
      >
        <v-card>
          <v-card-title class="text-h5 justify-lg-center" style="font-weight: 600">
            <div class="flex-grow-1 text-center">
                Mark as Paid
            </div>
            <div class="ml-auto">
                <v-btn icon @click="handleCloseModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
          </v-card-title>
          <hr class="mx-2 mb-5"/>

          <v-card-text>
            <p class="confirm-text">Here is the list to be marked as paid. Are you sure you want to proceed?</p>
            <div>
              <p class="confirm-text mb-1" style="font-size: 18px;"><strong>Total : {{formatNumber(this.total)}}</strong></p>
            </div>
            <table>
                <thead>
                    <th>ID</th>
                    <th>DATE</th>
                    <th>REQUESTOR</th>
                    <th>PROJECT NAME</th>
                    <th>AMOUNT</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    <template v-if="datas.length">
                        <tr v-for="(item,index) in datas" :key="index">
                            <td>
                                {{index + 1}}
                            </td>
                            <td>
                            {{ formattedDate(item.created_at) }}
                            </td>
                            <td>
                                {{item.user.full_name}}
                            </td>
                            <td>
                                {{item.project_name}}
                            </td>
                            <td>
                            {{formatNumber(item.total_amount)}}
                            </td>
                            <td>
                            <v-btn color="error" text @click="handleRemoveFromList(index)">Remove</v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="6">
                                <div class="d-flex justify-center pa-4">No records</div>
                            </td>
                        </tr>
                    </template>
                    
                </tbody>
            </table>
          </v-card-text>
          <v-card-actions class="d-flex justify-lg-space-between mt-2 pb-5" style="gap:20px">
            <v-btn
              color="primary"
                class="flex-grow-1 py-5"
              rounded
              @click="handleMarkAsPaid"
            >
              Proceed
            </v-btn>
            <v-btn
              color="white"
              class="flex-grow-1 py-5"
              rounded
              @click="handleCloseModal"
            >
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
import { changeFormat } from '@/utils/dateHelper';

export default {
    name:'DuplicateSentRequest',
    props: {
        open_modal: {
             type: Boolean,
        },
        items: {}
    },
    data () {
        return {
             dialog: false,
             total: 0,
             datas:[],
        }
    },
    methods:{
        formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

       },
        checkDialogClose(val) {
           if(!val){
               this.$emit('handleModalHide')
           }
       },
       handleCloseModal() {
            this.$emit('handleModalHide')
       },
       handleMarkAsPaid() {
            this.$emit('markAsPaid')
       },
       formattedDate(date) {
          return changeFormat(date); // Example format string
       },
       handleSendRequest(val) {
          this.$emit('sendBillRequest', val)
       },
       handleRemoveFromList(index) {
          this.datas.splice(index,1)
       },
    },

    watch: {
        'open_modal'(newValue) {
            this.dialog = newValue;
        },
        'items'(newValue) {
           this.total = 0
           this.datas = newValue
           newValue.forEach((value) => {
            this.total = this.total + value.net_premium
            // this.total = this.total + value.total_amount
           });
        },
    }
}
</script>

<style scoped>
.request-duplicate-confirmation{
    border-radius: 20px !important;
}
table {
        width: 100%;
        border-collapse: collapse;
}
table * {
    font-size: 13px !important;
    color: #000;
}
td, th {
    border: 1px solid #ddd;
    padding: 8px;
    width: fit-content;
}
th {
    background-color: #f6f6f6;
    color: #000;
}
.files_required{
    border: solid 3px red;
}
.confirm-text{
    font-family: inherit !important;
    color: #000 !important;
    width: 100%;
    font-size: 15px;
    margin-bottom: 20px;
}
</style>