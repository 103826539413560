export const descriptions = [
    { value: 1, text: 'NOA' },
    // { value: 3, text: 'PROOF OF LBC' },
    { value: 4, text: 'OR' },
    // { value: 4, text: 'INVOICE' },
    { value: 6, text: 'COMPLETED POLICY CONTRACT'},
    { value: 2, text: 'DRAFT' },
    // { value: 2, text: 'PROOF OF DELIVERY' },
    { value: 5, text: 'PROOF OF PAYMENT'},
    { value: 8, text: 'SCANNED COPY'},
    // { value: 8, text: 'QUOTATION'},
    { value: 9, text: 'SIGNED CONTRACT'},
    { value: 7, text: 'OTHERS'},
]

export default descriptions;