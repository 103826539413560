<template>
    <v-row justify="center">
     <v-dialog
       v-model="dialog"
       persistent
       top
       max-width="600px"
       @input="checkDialogClose"
     >      
       <v-card>
         <v-card-title class="grey lighten-2 d-flex justify-lg-space-between">
           <div>
               Upload Files
           </div>
           <v-btn icon @click="handleCloseDialog">
             <v-icon>mdi-close</v-icon>
         </v-btn>
         </v-card-title>
         <v-card-text class="px-5 mt-4">
            <div>
                
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <p class="ma-0" style="color: #000; font-size: 16px; font-weight: 500;">
                            Description : <strong style="color: red;">*</strong> 
                    </p>
                    <v-autocomplete
                        :items="descriptions"
                        v-model="payload.description_id"
                        text="text"
                        id="value"
                        outlined
                        :rules="descriptionRules"
                        dense
                    ></v-autocomplete>
                    <template v-if="show_others">
                        <p class="ma-0" style="color: #000; font-size: 16px; font-weight: 500;">
                                Other : 
                        </p>
                        <v-text-field
                            v-model="payload.other"
                            text="text"
                            id="value"
                            outlined
                            dense
                        ></v-text-field>
                    </template>

                </v-form>
            </div>
            <input
                type="file"
                multiple
                ref="file_input"
                class="d-none"
                accept="image/*, application/pdf"
                @change="getFiles"
            />
            <div class="attachments">
                <p class="mb-1" style="color: #000; font-size: 16px; font-weight: 500;">
                    Attachment/s
                </p>
               <div class="d-flex justify-center flex-column">
                <div v-if="!payload.files.length" class="attachment-upload" @click="handleSelectFile">
                    <!-- <p class="text-h5 ma-0" style="color: rgb(104 98 98);">Drag here</p> -->
                    <p>Click to upload file</p>
                </div>
                <div v-else class="attachment-upload file-lists align-start justify-lg-start pa-3" @click="handleSelectFile">
                    <div class="pa-2 file-container" v-for="(item, index) in payload.files" :key="index">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="file-wrapper" v-bind="attrs" v-on="on">
                                    <v-icon size="70">{{handleCheckFileType(item.type) == 'image' ? 'mdi-file-image' : 'mdi-file'}}</v-icon>
                                    <span>{{ truncateFileName(item.name) }}</span>
                                    <span class="remove-button" @click="handleRemoveFile($event,index)">
                                        <v-icon size="25">mdi-close-circle</v-icon>
                                    </span>
                                </div>
                            </template>
                            <span>{{item.name}}</span>
                        </v-tooltip>
                    </div>
                </div>
                <div class="add-file-button"  style="width: fit-content; margin: auto; padding: 20px;">
                    <v-btn
                        depressed
                        class="pa-5"
                        color="primary"
                        @click="handleSelectFile"
                        >
                        Add File
                    </v-btn>
                </div>
               </div>
            </div>
         </v-card-text>
         <v-card-actions class="d-flex justify-center">
           <v-btn
             depressed
             class="pa-5"
             color="primary"
             @click="handleSumbitFiles"
             >
             SUBMIT
             </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
   </template>
 
 <script>

import { descriptions } from  '@/helpers/description';

 export default {
     props: {
         open_modal: {
             type: Boolean,
         },
         file_type_upload: {
             type: String,
         },
         insurance_status: {},
     },
     data () {
         return {
             dialog: false,
             show_others: false,
             valid: true,
             descriptionRules: [
                 v => !!v || 'Description is required',
             ],
             fileRequire: ["image", "application"],
             descriptions : descriptions,
             payload : {
                 description_id : '',
                 files:[],
                 other:[],
             }
         }
     },
     methods:{
         checkDialogClose(val) {
             if(!val){
                 this.$emit('handleModalHide')
             }
         },
         handleCloseDialog() {
                 this.$emit('handleModalHide')
         },
         handleSelectFile() {
            this.$refs.file_input.click();
         },
         handleRemoveFile(event, index) {
            event.stopPropagation();
            this.payload.files.splice(index,1);
         },
         handleCheckFileType(file_type) {
            const type = file_type.split('/');
            return type[0];
         },
         truncateFileName(fileName, maxLength = 8) {
            if (fileName.length <= maxLength) {
                return fileName;
            }
            const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    
            const extension = fileName.split('.').pop();
            
            const truncatedFileNameWithoutExtension = fileNameWithoutExtension.slice(0, maxLength);
            const truncatedFileName = truncatedFileNameWithoutExtension + '...' + extension;

            return truncatedFileName;
         },
         getFiles(event) {
            var input = event.target;
            if (input.files) {
                Object.values(input.files).forEach(function (dataFile) {
                var split = dataFile["type"].split("/");
                if (this.fileRequire.includes(split[0])) {
                    if (dataFile["size"] <= 15000000) {
                        var data = {
                            type: dataFile["type"],
                            size: dataFile["size"],
                            name: dataFile["name"],
                        };
                        var reader = new FileReader();
                        reader.onload = (e) => {
                            data["file"] = e.target.result;
                            var is_include = this.payload.files.find(item=>item.name == data.name);
                            if(!is_include){
                                this.payload.files.push(data);
                            }
                        };
                        reader.readAsDataURL(dataFile);
                    } else {
                        this.$awn.alert(`The file ${dataFile['name']} too large for the 15MB limit`)
                    }
                } else {
                    this.$awn.alert(`Unsuforted File ${dataFile['name']}`)
                }
                }, this);
                // }
            }
        },
        handleSumbitFiles() {
            if(this.$refs.form.validate()){
                if(!this.payload.files.length){
                    return this.$awn.warning('No attachment uploaded');
                }
                this.$emit('handleSubmitFiles',this.payload)
                this.payload = {
                    description_id : '',
                    files:[],  
                }
            }
        },
     },
     watch: {
         'open_modal'(newValue) {
             this.dialog = newValue;
         },
         'payload.description_id'(newValue) {
            if(newValue == 7){
                this.show_others = true;
            }
            else{
                this.show_others = false;
            }
         },
         'file_type_upload'(newValue) {
             if(newValue == 'for aetos'){
                let descriptions = '';
                if(this.insurance_status > 3){
                    descriptions = this.descriptions.filter(header => {
                        return header.text.toLowerCase() === 'others' || header.text.toLowerCase() === 'signed contract' || header.text.toLowerCase() === 'proof of payment' || header.text.toLowerCase() === 'noa';
                    });
                }
                else{
                    descriptions = this.descriptions.filter(header => {
                        return header.text.toLowerCase() === 'others' || header.text.toLowerCase() === 'noa';
                    });
                }

                this.descriptions = descriptions;
             }
             else{
                const descriptions = this.descriptions.filter(header => {
                    return header.text.toLowerCase() !== 'proof of payment' && header.text.toLowerCase() !== 'noa' && header.text.toLowerCase() !== 'signed contract';
                    // return header.text.toLowerCase() !== 'others' && header.text.toLowerCase() !== 'proof of payment' && header.text !== 'noa';
                });
                this.descriptions = descriptions;
             }
         }
     }
 }
 </script>