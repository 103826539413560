<template>
  <div class="pt-5">

        <v-card class="pt-8 pb-8 mt-0 v-card-container">
            <div class="d-flex mb-5 billing-info" style="gap: 50px;">
                <span v-if="user.is_admin"><strong>Company</strong> : {{this.company_name}}</span>
                <span><strong>Email</strong> : {{this.billing_email}}</span>
            </div>
            <v-card class="pa-3 mt-0 d-flex justify-lg-space-between align-center" style="gap: 20px;">
              <div class="btn-mark-as-paid" v-if="user.is_admin">
                  <v-btn color="primary" @click="handleMarkAsRead" class="py-4" :disabled="selected.length ? false : true">
                      Mark as paid
                  </v-btn>
              </div>
              <div class="d-flex flex-row align-center" style="gap: 20px;">
                  <label>Payment Status</label>
                  <v-autocomplete
                    v-model="query.payment_status"
                    :items="payment_status"
                    text="text"
                    id="value"
                    label="Payment Status"
                    solo
                    outlined
                    hide-details=""
                    dense
                    clearable
                    @change="getCompanyBillingDetails()"
                  ></v-autocomplete>
              </div>
              <div class="d-flex flex-row align-center" style="gap: 20px;" v-if="!user.is_admin">
                  <label>Company</label>
                  <v-autocomplete
                    v-model="query.sub_company_id"
                    :items="sub_company"
                    text="text"
                    id="value"
                    label="Company"
                    solo
                    outlined
                    hide-details=""
                    dense
                    clearable
                    @change="getCompanyBillingDetails()"
                  ></v-autocomplete>
              </div>
              <div class="wrapper-action d-flex align-center" style="gap: 12px;" v-if="datas">
                  <div class="d-flex align-center total-amount-wrapper">
                    <h3>Total Amount</h3>
                    <div class="ml-4">
                      <!-- {{datas.data.length ? parseFloat(this.total_amount).toFixed(2) : 0}} -->
                       {{this.total_amount ? formatNumber(this.total_amount) : 0}}
                      <!-- {{datas.data.length ? formatNumber(this.total_amount) : 0}} -->
                    </div>
                  </div> 
                  <v-btn v-if="user.is_admin" color="primary" class="py-4 send" @click="handleSendBill" :loading = "loading" :disabled="selected.length ? false : true">
                  <!-- <v-btn v-if="user.is_admin" color="primary" class="py-4 send" @click="handleSendBill" :loading = "loading" :disabled="has_to_send.length ? false : true"> -->
                      Send Bill
                      <v-icon class="ml-3">mdi-send</v-icon>
                  </v-btn>
              </div>
            </v-card> 
            <div class="mt-5">
              <div class="table-wrapper">
                  <v-card>
                  <v-data-table
                      v-model="selected"
                      :loading="loading_table"
                      :headers="headers"
                      :items="datas.data"
                      :page.sync="page"
                      :items-per-page="10"
                      hide-default-footer
                      :show-select="user.is_admin && checkIfHasUnpaidList() ? true :false"
                      class="elevation-1"
                      @page-count="pageCount = $event"
                      >
                      <!-- @toggle-select-all="selectAllToggle" -->
                    <template v-slot:item.data-table-select="{ item, isSelected, select }">
                      <v-simple-checkbox
                        :value="isSelected && item.payment_status != 3 ? true : false"
                        :readonly="item.payment_status == 3 ? true : false"
                        :disabled="item.payment_status == 3 ? true : false"
                        @input="select($event)"
                      ></v-simple-checkbox>
                    </template>
                  <template v-slot:item.id="{ item, index }">
                      <!-- {{ index + 1 }} -->
                        {{ item.id }}
                  </template>
                  <template v-slot:item.created_at="{ item, index }">
                      {{ formattedDate(item.created_at) }}
                  </template>
                  <template v-slot:item.company="{ item, index }">
                      {{ item.user.client_company && item.user.client_company.company.name }}
                  </template>
  
                  <template v-slot:item.in_charge="{ item }">
                      {{ item.in_charge.first_name }} {{ item.in_charge.last_name }}
                  </template>
  
                  <template v-slot:item.billing_email="{ item }">
                      {{item.billing.email_address}}
                  </template>
  
                  <template v-slot:item.total_amount="{ item }">
                      {{formatNumber(item.net_premium)}}
                      <!-- {{formatNumber(parseInt(item.net_premium))}} -->
                      <!-- {{(formatNumber(parseInt(item.noa_amount) + parseInt(item.insured_amount) + ( item.net_premium && parseInt(item.net_premium))))}} -->
                  </template>
  
                  <template v-slot:item.attachments="{ item }">
                      <v-btn text color="primary" style="text-transform: capitalize;" v-if="item.proof_of_payment.length" @click="handleShowAttachment(item.proof_of_payment)">
                        <v-icon size="18" class="mr-2">mdi-eye</v-icon>
                        Show
                      </v-btn>
                  </template>

                  <template v-slot:item.payment_status="{ item }">
                      {{ handleGetPaymentStatus(item.payment_status) }}
                      <template v-if="item.payment_status == 1 && item.request_sent">
                        <v-chip small color="red" text-color="white">Pending</v-chip>
                      </template>
                  </template>
  
                  <template v-slot:item.action="{ item }">
                      <v-btn color="primary" icon  @click="goTo(item)">
                          <v-icon size="18">mdi-eye</v-icon>
                      </v-btn> 
                  </template>
                                                          
                  
                  </v-data-table>
                  </v-card> 
               </div>
              <div class="text-center v-pagination-wrapper mt-5">
                <v-pagination
                  v-model="page"
                  :length="datas.last_page"
                  @input="handlePageChange"
                ></v-pagination>
              </div>
            </div>
        </v-card>
        <DuplicateRequestSent :open_modal="show_confirm_modal" :items="exclude_ids" :selected="filterSelected" @handleModalHide="show_confirm_modal = false" @sendBillRequest="sendBillRequest"/>
        <MarkAsPaid :open_modal="show_mark_as_paid_modal" :items="filterSelected" @handleModalHide="show_mark_as_paid_modal = false" @markAsPaid="markAsPaid"/>
        <AttachmentList :open_modal="show_attachment_list_modal" :attachments="attachments" @handleModalHide="show_attachment_list_modal = false"/>
        <BillingDetails :open_modal="show_billing_details" :billing_content="billing_content" :cc="cc" :email_sender="email_sender" :billing_email ="billing_email" @sendBill="sendBill"  @handleModalHide="show_billing_details = false"/>
  </div>
</template>

<script>
import { changeFormat } from '@/utils/dateHelper';
import { payment_status } from  '@/helpers/payment_status';
import { insurance_status } from  '@/helpers/insurance_status';
import DuplicateRequestSent from  '../modals/duplicate_request_sent.vue';
import AttachmentList from  '../modals/Attachment_list.vue';
import MarkAsPaid from  '../modals/mark_as_paid.vue';
import BillingDetails from  '../modals/BillingDetails.vue';


export default {
  components:{
    DuplicateRequestSent,
    MarkAsPaid,
    AttachmentList,
    BillingDetails
  },
  data () {
    return {
      selected : [],
      filterSelected : [],
      loading : false,
      has_to_send : [],
      ids_no_need_to_send : [],
      attachments : [],
      billing_content : '',
      cc : '',
      email_sender : '',
      pm_status : '',
      show_confirm_modal : false,
      show_mark_as_paid_modal : false,
      show_billing_details : false,
      show_attachment_list_modal : false,
      loading_table : false,
      company_name : null,
      billing_email : null,
      total_amount : 0,
      user : this.$store.getters.user.user,
      search:null,
      showeducationmodal:false,
      showeligibilitymodal:false,
      payment_status:payment_status,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Date Created', value: 'created_at' },
        { text: 'Policy No.', value: 'policy_no' },
        { text: 'Requestor', value: 'user.full_name' },
        { text: 'Project Name', value: 'project_name' },
        { text: 'Company', value: 'sub_company.name' },
        { text: 'Amount', value: 'total_amount' },
        { text: 'Attachments', value: 'attachments' },
        { text: 'STATUS', value: 'payment_status' },
        { text: 'Action', value: 'action' },
      ],
      datas:[],                             
      total_page:0,                             
      companies:[],
      exclude_ids:[],
      sub_company:[],
      query:{
        company : null,
        payment_status : null,
        sub_company_id : null,
      },
    }
  },
  created(){
    if(this.$store.getters.user.user.is_admin){
      const header = this.headers.filter(item=>{
        return item.text != 'Company';
      });
      this.headers = header;
    }

  },
  mounted(){
    this.getCompanyBillingDetails();
  },

  methods:{
      selectAllToggle(props) {
        // if(!this.selected.length) {
        //   this.selected = [];
        //   props.items.forEach(item => {
        //     if(item.payment_status != 3) {
        //       this.selected.push(item);
        //     } 
        //   });
        // } else this.selected = [];
        props.items.forEach(item => {
            // if(item.payment_status != 3) {
              const check = this.isIncluded(item)
              if(check == -1){
                this.selected.push(item);
              } 
              else{
                // if(props.value){
                //   this.selected.push(item);
                // }else{
                  this.selected.splice(check, 1);
                // }
              }
            // }
          });
      },
      isIncluded(item) {
        return this.selected.findIndex(selected => selected.id == item.id);
      },  
      isSelected(item) {
        return this.selected.includes(item);
      },
      // formatNumber(value) {
      //     if (typeof value !== 'string') {
      //         value = String(value || ''); // Ensure value is a string
      //     }
      //       // const [integerPart, decimalPart] = value.split('.');
      //       // const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      //       // const total = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
      //       return parseFloat(value).toFixed(2)
      // },
      formatNumber(value) {
      // Custom formatting function: add commas and two decimals
        if(value){
          return value
            .toFixed(2) // Ensure two decimal places
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
        }
        return value;

      },
      formattedDate(date) {
        return changeFormat(date); // Example format string
      },
      handleSendBill() {
        if(!this.selected.length){
         return this.$awn.alert('No Bill To Send')
        }
        if(this.exclude_ids.length){
          let exists = false;
          this.exclude_ids.forEach((value) => {
           const exists_in_selected = this.filterSelected.find(item=>item.id == value.id);
           if(exists_in_selected){
            exists = true;
            return this.show_confirm_modal = true;
           }
          });
          if(exists){
            return true;
          }
        }
        this.$notiflix.Confirm.show(
            'SEND BILL',
            'Are you sure you want to send selected request?', // HTML Message
            'Yes',
            'No',
            () => {
                this.handleShowTemplate();
                // this.sendBill();
            },
        );
      },
      handleShowTemplate(exclude_ids = []) {
          var params ={
            company_id :this.$route.params.company_id,
            billing_id :this.$route.params.billing_id,
            exclude_ids :exclude_ids,
          }
          this.ids_no_need_to_send = exclude_ids;

          const selected_ids = this.filterSelected.map((item)=>{
            return item.id;
          })
          // const selected_ids = this.selected.map((item)=>{
          //   return item.id;
          // })

          this.$notiflix.Loading.hourglass('Previewing an email...');
          this.axios.post('/billings/show-template-bill',{...params, include_ids : selected_ids}).then((res)=>{
            // this.$awn.success('Bill Send Successfully'),
            this.billing_content = res.data.data;
            this.email_sender = res.data.sender;
            this.show_billing_details = true;
            this.cc = res.data.cc;
            // this.getCompanyBillingDetails();
          }).catch((err)=>{
              console.log(err,'err')
          }).finally(()=>{
            this.$notiflix.Loading.remove();
            this.loading = false;
          })
      },

      handleMarkAsRead() {
        if(!this.selected.length){
          return this.$awn.alert('Please select list!')
        }
        this.show_mark_as_paid_modal = true
      },
      handleShowAttachment(attachments) {
        this.attachments = attachments;
        this.show_attachment_list_modal = true
      },
      markAsPaid() {
        this.show_mark_as_paid_modal = false
        if(!this.selected.length){
          return this.$awn.alert('Please select list!')
        }
        const ids = this.filterSelected.map((item,index)=>{
          return item.id;
        })
        // const ids = this.selected.map((item,index)=>{
        //   return item.id;
        // })

        this.$notiflix.Loading.arrows();
        this.axios.post(`/request/update-pm-status`,ids).then((res)=>{
          this.$awn.success('Updated Successfully');
          this.getCompanyBillingDetails();
          this.selected = [];
          this.filterSelected = [];
        }).catch((err)=>{
            console.log(err,'err')
        }).finally(()=>{
          this.$notiflix.Loading.remove();
        })
      },
      sendBillRequest(bol) {
         var ids = [];
         if(!bol){
            ids = this.exclude_ids.map((item)=>{
              return item.id
            })

            const check = this.arraysAreEqual(this.exclude_ids, this.filterSelected);
            // const check = this.arraysAreEqual(this.exclude_ids, this.selected);
            if(check){
              this.show_confirm_modal = false;
              return this.$awn.alert('No bill will be will be send');
            }
         }

         this.show_confirm_modal = false;
         this.handleShowTemplate(ids);

      },
      arraysAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        return true;
      },
      sendBill() {
          this.loading = true;
          const selected_ids = this.filterSelected.map((item)=>{
            return item.id;
          })  
          // const selected_ids = this.selected.map((item)=>{
          //   return item.id;
          // })
          var params ={
            company_id :this.$route.params.company_id,
            billing_id :this.$route.params.billing_id,
            exclude_ids :this.ids_no_need_to_send,
            include_ids :selected_ids,
          }

          this.$notiflix.Loading.arrows();
          this.axios.post('/billings/send-bill',params).then((res)=>{
            this.$awn.success('Bill Send Successfully'),
            this.show_billing_details = false
            this.selected = [];
            this.filterSelected = [];
            this.getCompanyBillingDetails();
          }).catch((err)=>{
              console.log(err,'err')
          }).finally(()=>{
            this.$notiflix.Loading.remove();
            this.loading = false;
          })
      },
      handleGetPaymentStatus(id) {
       const status_payment = payment_status.find(item=>item.value == id);
       return status_payment.text
      },
      handlePageChange() {
        this.getCompanyBillingDetails();
      },
      handleGetInsuranceStatus(id) {
       const status = insurance_status.find(item=>item.value == id);
       return status.text
      },
      goTo(item){
          this.$router.push(`/application/view/${item.id}`)
      },
      goToSumarry(item){
          this.$router.push(`/billing/${item.user.client_company.company_id}/${item.billing_id}`)
      },
      getCompanyBillingDetails(){
          this.$notiflix.Loading.arrows();
           this.axios.get(`/billings/${this.$route.params.company_id}/${this.$route.params.billing_id}?page=${this.page}&search=${JSON.stringify(this.query)}`).then((res)=>{
              this.exclude_ids = res.data.data.data.filter(item => {
                  return item.request_sent && item.payment_status == 1 ;
              });
              this.has_to_send = res.data.data.data.filter(item => {
                  return item.payment_status == 1 ;
              });
              this.company_name = res.data.company
              this.billing_email = res.data.email
              // this.total_amount = res.data.sum
              this.datas = res.data.data
              this.total_page = res.data.data.total
              this.sub_company = res.data.sub_company.map((item)=>{
                return {
                  value : item.id,
                  text : item.name,
                }
              })
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
           
      },
      getCompany(){
           this.$notiflix.Loading.arrows();
           this.axios.get(`/user/get-company`).then((res)=>{
           this.companies = res.data.company.map((item)=>{
                return{
                  value:item.id,
                  text:item.name,
                }
            });
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
      },    
      checkIfHasUnpaidList(){
        const unpaidlist = this.datas.data.filter(item => item.payment_status !== 3);
        if(unpaidlist.length) return true;
        else return false;
      },
  },
  watch: {
      'selected'(newValue) {
          this.total_amount = 0;
          // const filteredValues = newValue.filter(item => item.payment_status === 3);
          // if(filteredValues.length){
          //   const new_value = newValue.filter(item => item.payment_status !== 3);
          //   this.selected = new_value;
          // }
          const new_value = newValue.filter(item => item.payment_status !== 3);
          this.filterSelected = new_value;
          // console.log(filteredValues,'qwewe')
          newValue.forEach((value) => {
             if(value.payment_status != 3) {
                this.total_amount = this.total_amount + value.net_premium
              }
          });
          // console.log(newValue,'newValue')

      },
     }
}
</script>

<style scoped>
 .billing-user-wrapper{
      background-color: #cdf4f6;
    height: 100%;
  }
  .table-wrapper{
    background: #fff;
    padding: 0 !important;
    border-radius: 10px;
  }
  .table-wrapper .v-card{
      padding: 10px;
  }
  .v-card-container{
  }
  .btn-mark-as-paid button{
      font-weight: 600;
      background-color: #6334e3 !important;
  }
  .total-amount-wrapper div{
    border: solid 1px #d1d0d0;
    padding: 10px 30px;
    border-radius: 8px;
    background-color: #f7f6f6;
    color: #000;
  }
  .wrapper-action .send{
    font-weight: 700;
    background-color: #ffa834 !important
  }
  .wrapper-action .resend{
    font-weight: 700;
    background-color: red !important
  }
  .billing-info span{
    font-size: 20px;
  }
</style>