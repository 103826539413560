<template>
    <div>
        <v-card class="p-5 v-card-container">
            <div class="input-wrapper">
                <v-form
                    class="request-form"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" class="py-0">
                            <div>
                                <label for="in-charge">
                                        In Charge : 
                                </label>
                                <strong style="color: red; font-size: 20px;">&nbsp; *</strong> 
                            </div>
                            <v-autocomplete
                                :items="user_incharge"
                                v-model="payload.in_charge_id"
                                :rules="handleValidation('Incharge')"
                                text="text"
                                id="value"
                                outlined
                                solo
                                dense
                                :readonly="true"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <div class="d-flex justify-lg-space-between">
                                <div>
                                    <label for="in-charge">
                                    Company : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>
                                <template v-if="!user.is_admin">
                                    <v-btn rounded small color="secondary" @click="company_modal = true">
                                    <!-- <v-btn rounded small color="secondary" @click="company_modal = true" v-if="user.default_user_company.role == 'SA' || user.default_user_company.role == 'CA'"> -->
                                        <v-icon size="12" class="mr-1">mdi-plus-circle</v-icon>New
                                    </v-btn>
                                </template>
                            </div>
                            <div class="d-flex">
                                <v-autocomplete
                                    :items="company_list"
                                    v-model="payload.company_id"
                                    :readonly="handleDontAllowEdit() ? true : false"
                                    :rules="handleValidation('Company')"
                                    text="text"
                                    id="value"
                                    solo
                                    outlined
                                    dense
                                    :clearable="!handleDontAllowEdit() ? true : false"
                                >
                            
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-list-item-content>
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="d-flex flex-row align-center">
                                                <v-btn icon @click="handleEditCompanyList($event,item)">
                                                    <v-icon size="20">mdi-pencil-circle</v-icon>
                                                </v-btn>
                                                |
                                                <v-btn icon @click="handleDeleteCompanyList($event,item)">
                                                    <v-icon size="20" color="red">mdi-delete-circle</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                    </v-list-item>
                                </template>

                                </v-autocomplete>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0" style="margin-top: -10px !important;">
                        <v-col cols="12" sm="6" class="py-0">
                            <div class="d-flex justify-lg-space-between">
                                <div>
                                    <label for="in-charge">
                                    Name of Procuring Entity : 
                                </label>
                                <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>
                                <v-btn rounded small color="secondary" @click="procuring_modal = true" v-if="payload.user_id == user.id">
                                    <v-icon size="12" class="mr-1">mdi-plus-circle</v-icon>New
                                </v-btn>
                            </div>
                            <div class="d-flex">
                                <v-autocomplete
                                    :items="procuring_entity_list"
                                    v-model="payload.procuring_entity_id"
                                    :readonly="handleDontAllowEdit() ? true : false"
                                    :rules="handleValidation('Company')"
                                    text="text"
                                    id="value"
                                    solo
                                    outlined
                                    dense
                                    :clearable="!handleDontAllowEdit() ? true : false"
                                >
                            
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-list-item-content>
                                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="d-flex flex-row align-center">
                                                <v-btn icon @click="handleEditList($event,item)">
                                                    <v-icon size="21">mdi-pencil-circle</v-icon>
                                                </v-btn>
                                                |
                                                <v-btn icon @click="handleDeleteList($event,item)">
                                                    <v-icon size="21" color="red">mdi-delete-circle</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                    </v-list-item>
                                </template>

                                </v-autocomplete>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                                <div>
                                    <label for="in-charge">
                                            Address of Procuring Entity : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>

                                <v-text-field
                                    id="in-charge"
                                    :rules="handleValidation('Address of Procuring Entity')"
                                    v-model="procuring_address"
                                    dense
                                    solo
                                    outlined
                                    readonly
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0" style="margin-top: -10px !important;">
                        <v-col cols="12" sm="12" class="py-0">
                                <div >
                                    <label for="in-charge">
                                            Project Name : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>

                                <v-text-field
                                    id="in-charge"
                                    v-model="payload.project_name"
                                    :readonly="handleDontAllowEdit() ? true : false"
                                    :rules="handleValidation('Project')"
                                    dense
                                    solo
                                    outlined
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0" style="margin-top: -10px !important;">
                        <v-col cols="12" sm="4" class="py-0">
                            <div>
                                <label for="in-charge">
                                        Amount (NOA) : 
                                </label>
                                <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                            </div>
                            <v-text-field
                                v-model="noa_amount"
                                :rules="handleValidation('Noa')"
                                @keydown="isNumber($event)"
                                dense
                                solo 
                                outlined
                                :readonly="handleDontAllowEdit() ? true : false"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4"  class="py-0">
                                <div>
                                    <label for="in-charge">
                                            Insured : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>
                                <v-text-field
                                    id="in-charge"
                                    :rules="handleValidation('Insured Amount')"
                                    v-model="insured_amount"
                                    @keydown="isNumber($event)"
                                    :readonly="handleDontAllowEdit() ? true : false"
                                    dense
                                    solo
                                    outlined
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" :sm="payload.validity != 'Other'? '4' : '3'" class="py-0">
                                <div>
                                    <label for="in-charge">
                                            Product Classification : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>
                                <v-text-field
                                    id="in-charge"
                                    v-model="payload.product_classification"
                                    :readonly="handleDontAllowEdit() ? true : false"
                                    :rules="handleValidation('Product Classification')"
                                    dense
                                    solo
                                    outlined
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0" style="margin-top: -10px !important;">
                        <v-col cols="12" :sm="payload.validity != 'Other'? '4' : '3'" class="py-0">
                                <div >
                                    <label for="in-charge">
                                        Open Date : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"><!--  &nbsp; * --></strong> 
                                </div>
                                <v-switch
                                v-model="payload.open_date"
                                inset
                                class="mt-2"
                                :readonly="handleDontAllowEdit()? true : false"
                                :label="`${payload.open_date ? 'Yes' : 'No'}`"
                                ></v-switch>
                        </v-col>
                        <v-col cols="12" :sm="payload.validity != 'Other'? '4' : '3'" class="py-0">
                                <div >
                                    <label for="in-charge">
                                        Start Date : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> {{ !payload.open_date ?  `&nbsp; *` : ''}}</strong> 
                                </div>
                                 <v-menu
                                        v-model="menuFrom"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <!-- :rules="handleValidation('Stard Date')" -->
                                            
                                        <v-text-field
                                            id="in-charge"
                                            v-model="payload.start_date"
                                            :readonly="handleDontAllowEdit()? true : false"
                                            :rules="!payload.open_date? handleValidation('Stard Date') : []"
                                            dense
                                            solo
                                            outlined
                                            v-bind="attrs"
                                            v-on="!payload.open_date && !handleDontAllowEdit() ? on : null"
                                            autocomplete="off"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="payload.start_date"
                                            :readonly="handleDontAllowEdit()? true : false"
                                            no-title
                                            @input="menuFrom = false"
                                            ></v-date-picker>
                                    </v-menu>
                        </v-col>
                        <v-col cols="12" :sm="payload.validity != 'Other'? '4' : '3'" class="py-0">
                                <div>
                                    <label for="in-charge">
                                            Validity : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; *</strong> 
                                </div>
                                <v-autocomplete
                                    :items="validity"
                                    v-model="payload.validity"
                                    text="text"
                                    id="value"
                                    outlined
                                    solo
                                    :rules="handleValidation('Validity')"
                                    :clearable="!handleDontAllowEdit() ? true : false"
                                    dense
                                     :readonly="handleDontAllowEdit() ? true : false"
                                ></v-autocomplete>
                        </v-col>
                        <v-col v-if="payload.validity == 'Other'" cols="12" sm="3" class="py-0">
                                <div>
                                    <strong style="color: red; font-size: 20px;"> &nbsp; </strong> 
                                </div>
                                <v-text-field
                                    id="in-charge"
                                    v-model="other_validity"
                                    :readonly="handleDontAllowEdit() ? true : false"
                                    :rules="handleValidation('Others')"
                                    dense
                                    solo
                                    outlined
                                ></v-text-field>
                        </v-col>
                       
                    </v-row>
                    <v-row class="ma-0" style="margin-top: -10px !important;">
                        <v-col cols="12" sm="4" class="py-0">
                                <div>
                                    <label for="in-charge">
                                        Policy No.: 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"></strong> 
                                </div>
                                <v-text-field
                                    id="in-charge"
                                    v-model="payload.policy_no"
                                    :rules="(payload.insurance_status > 3  && payload.insurance_status < 7) || payload.insurance_status === 9? handleValidation('Policy No') : []"
                                    
                                    dense
                                    solo
                                    outlined
                                    :readonly="user.is_admin && payload.insurance_status >= 3 && payload.insurance_status < 7 || payload.insurance_status === 9 ? false : true"
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                                <div>
                                    <label for="in-charge">
                                            Net Premium : 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"></strong> 
                                </div>
                                <v-text-field
                                    id="in-charge"
                                    v-model="net_premium"
                                    :rules="(payload.insurance_status > 3  && payload.insurance_status < 7) || payload.insurance_status === 9? handleValidation('Net Premium') : []"
                                    @keydown="isNumber($event)"
                                    dense
                                    solo
                                    outlined
                                    :readonly="user.is_admin && payload.insurance_status >= 3 && payload.insurance_status < 7 || payload.insurance_status === 9 ? false : true"
                                ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                                <div >
                                    <label for="in-charge">
                                        Due Date (Kelan Kailangan?):
                                    </label>
                                    <strong style="color: red; font-size: 20px;"> </strong> 
                                    <!-- <strong style="color: red; font-size: 20px;"> {{ !payload.id ?  `&nbsp; *` : ''}}</strong>  -->
                                </div>
                                 <v-menu
                                        v-model="dateNeeded"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <!-- :rules="handleValidation('Stard Date')" -->
                                            <!-- :rules="!payload.id? handleValidation('Date Need') : []" -->
                                            
                                            <v-text-field
                                            id="in-charge"
                                            v-model="payload.date_needed"
                                            :readonly="handleDontAllowEdit()? true : false"
                                            dense
                                            solo
                                            outlined
                                            v-bind="attrs"
                                            v-on="!payload.date_needed && !handleDontAllowEdit() ? on : null"
                                            autocomplete="off"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="payload.date_needed"
                                            :readonly="handleDontAllowEdit()? true : false"
                                            no-title
                                            @input="dateNeeded = false"
                                            ></v-date-picker>
                                    </v-menu>
                        </v-col>
                    </v-row>    
                    <v-row class="ma-0" style="margin-top: -10px !important;">
                        <v-col cols="12" sm="12" class="py-0">
                                <div>
                                    <label for="in-charge">
                                        Remarks.: 
                                    </label>
                                    <strong style="color: red; font-size: 20px;"></strong> 
                                </div>
                                <v-textarea
                                dense
                                solo
                                outlined
                                hide-details
                                :readonly="handleDontAllowEdit() ? true : false"
                                rows="4"
                                v-model="payload.remarks"
                                name="remarks"
                                placeholder="Add remarks"
                                ></v-textarea>
                        </v-col>
                    </v-row>
                  
                </v-form>
            </div>
            <!-- ic certification -->
            <div class="radio-wrapper">
                <div class="container-wrapper">
                    <label>IC Certification Needed?</label>
                    <v-radio-group
                        v-model="payload.ic_certification_needed"
                        row
                        :readonly="handleDontAllowEdit() ? true : false"
                        >
                        <v-radio
                            label="Yes"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            label="No"
                            :value="0"
                        ></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <!-- hard copy certification -->
            <div class="radio-wrapper">
                <div :class="`flex-grow-1`">
                    <label>Hard Copy</label>
                    <v-radio-group
                        v-model="payload.hard_copy"
                        :readonly="handleDontAllowEdit() ? true : false"
                        row
                        >
                        <v-radio
                            label="Yes"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            label="No"
                            :value="0"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <template v-if="payload.hard_copy">
                    <hr/>
                    <div :class="`radio-table-container ${files_required === 'hard_copy' ? `fields_required` : ''}`">
                        <div class="mb-3 d-flex align-center" v-if="payload.user_id === user.id || !$route.params.id">
                        <!-- <div class="mb-3 d-flex align-center" v-if="user.default_user_company.role != 'SA' || !$route.params.id"> -->
                            <h4 class="mr-2">Mail To : </h4>
                            <v-btn v-if="!$route.params.id || payload.insurance_status == 1" icon @click="mailing_modal = true">
                                <v-icon color="#000">mdi-plus-circle</v-icon>
                            </v-btn>
                        </div>
                        <div v-if="$route.params.id && payload.insurance_status > 1" class="form-table-wrapper">
                        <!-- <div v-if="this.user.default_user_company.role == 'SA' && $route.params.id"> -->
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Name</strong>
                                        </td>
                                        <td>
                                            {{payload.mailing && payload.mailing.name}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Contact No.</strong>
                                        </td>
                                        <td>
                                            {{payload.mailing && payload.mailing.contact_no}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Address</strong>
                                        </td>
                                        <td>
                                            {{payload.mailing && payload.mailing.address}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-radio-group v-else
                            v-model="payload.mail_address_id"
                            :readonly="handleDontAllowEdit() ? true : false"
                            row>
                            <div class="radio-wrapper-table" v-for="(item, index) in mailing_address" :key="index">
                                <div class="d-flex justify-lg-space-between mb-2">
                                    <v-radio
                                        :value="item.id"
                                    ></v-radio>
                                    <div class="actions">
                                        <v-btn icon @click="handleEditMailAddress(item)">
                                            <v-icon size="22">mdi-pencil-circle</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="handleDeleteMailAddress(item)">
                                            <v-icon size="22" color="red">mdi-delete-circle</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="hard-copy-table">
                                    <table class="table table-bordered">
                                        <tbody >
                                            <tr>
                                                <td>Name  </td>
                                                <td>&nbsp;  &nbsp; {{item.name}}</td>
                                            </tr>
                                            <tr>
                                                <td>Contact No.  </td>
                                                <td>&nbsp;  &nbsp; {{item.contact_no}}</td>
                                            </tr>
                                            <tr>
                                                <td>Address  </td>
                                                <td>&nbsp;  &nbsp; {{item.address}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </v-radio-group>
                    </div>
                </template>

            </div>
            <!-- attachment -->
            <div class="v-card-container-attachment mt-4">
                    <v-card class="flex-grow-1 elevation-0">
                        <v-card-title>
                             <h5>Attachments</h5>
                        </v-card-title>
                        <v-card-text  class="table-attachment">
                            <v-card :class="`flex-grow-1 ${files_required === 'for_aetos' ? `fields_required` : ''}`" style="max-width: 50%;">
                                <v-card-title>
                                    <h5>For AETOS</h5>
                                </v-card-title>
                                <v-card-text class="table-attachment-wrapper">
                                    <v-data-table
                                        :headers="headers"
                                        :items="payload.for_aetos_files"
                                        :sort-by="['calories', 'fat']"
                                        :sort-desc="[false, true]"
                                        multi-sort
                                        hide-default-footer
                                    >
                                    <template v-slot:item.created_at="{ item }">
                                        {{item.created_at}}

                                    </template>
                                                            
                                    <template v-slot:item.attachment="{ item }">
                                        <div class="d-flex align-center">
                                            {{ item.file_name }}

                                            <div v-if="item.id && payload.insurance_status > 1" class="d-flex align-center">
                                                <v-btn icon @click="handleDownloadAttachment(item)">
                                                    <v-icon color="blue">mdi-download</v-icon>
                                                </v-btn>
                                                <div v-if="item.total_download"  @click="handleshowDownloadLogs(item)">
                                                    <v-badge type="button"
                                                            style="cursor: pointer;"
                                                            color="red"
                                                            :content="item.total_download"
                                                            >
                                                    </v-badge>
                                                </div>
                                            </div>
                                            <div v-else-if="item.id && payload.insurance_status == 1" class="d-flex align-center">
                                                <v-btn icon @click="handleAttachmentToRemove(item, 'for_aetos_files')">
                                                    <v-icon color="blue">mdi-close-circle</v-icon>
                                                </v-btn>
                                            </div>
                                            <v-btn icon v-else @click="handleRemoveAttachment(item, 'for_aetos_files')">
                                                <v-icon color="blue">mdi-close-circle</v-icon>
                                            </v-btn>

                                        </div>

                                    </template>
                                                            
                                    </v-data-table>
                                    <v-btn v-if="payload.user_id == user.id" color="primary" class="mt-10"  @click="handleShowUploadFile('for aetos')">
                                        <v-icon>mdi-plus</v-icon>
                                        Upload
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                            <v-card :class="`flex-grow-1 ${files_required === 'for_client' ? `fields_required` : ''}`" style="max-width: 50%;">
                                <v-card-title>
                                    <h5>For Client</h5>
                                </v-card-title>
                                <v-card-text class="table-attachment-wrapper">
                                    <v-data-table
                                        :headers="headers"
                                        :items="payload.for_client_files"
                                        :sort-by="['calories', 'fat']"
                                        :sort-desc="[false, true]"
                                        multi-sort
                                        hide-default-footer
                                    >
                                    <template v-slot:item.attachment="{ item }">
                                        <div class="d-flex align-center">
                                            {{ item.file_name }} 
                                            <div v-if="item.id" class="d-flex align-center">
                                                <v-btn icon @click="handleDownloadAttachment(item)">
                                                    <v-icon color="blue">mdi-download</v-icon>
                                                </v-btn>
                                                <div v-if="item.total_download"  @click="handleshowDownloadLogs(item)">
                                                    <v-badge type="button"
                                                            style="cursor: pointer;"
                                                            color="red"
                                                            :content="item.total_download"
                                                            >
                                                    </v-badge>
                                                </div>
                                            </div>
                                            <v-btn icon v-else @click="handleRemoveAttachment(item, 'for_client_files')">
                                                <v-icon color="blue">mdi-close-circle</v-icon>
                                            </v-btn>
                                        </div>

                                    </template>
                                    </v-data-table>

                                    <div class="mt-4">
                                        <v-btn color="primary"  v-if="user.is_admin" @click="handleShowUploadFile('for client')">
                                            <v-icon>mdi-plus</v-icon>
                                            Upload
                                        </v-btn>
                                    </div>
                                </v-card-text>
                                
                            </v-card>
                        </v-card-text>
                    </v-card>
            </div>
            <!-- billing -->
            <div :class="`radio-wrapper ${files_required === 'billing' ? `fields_required` : ''}`">
                <div class="d-flex align-center py-1">
                    <div class="container-wrapper pa-2 flex-grow-1">
                        <label>SEND BILL TO</label>
                        <!-- <v-btn icon @click="billing_modal = true" v-if="payload.user_id == user.id || !$route.params.id"> -->
                        <v-btn icon @click="billing_modal = true" v-if="!user.is_admin">
                            <v-icon color="#000">mdi-plus-circle</v-icon>
                        </v-btn>
                    </div>
                    <!-- <v-text-field
                        dense
                        outlined 
                        hide-details
                        solo
                        label="Search"
                    ></v-text-field> -->
                </div>
                <hr/>
                <div v-if="$route.params.id && payload.insurance_status > 1" class="py-4">
                    <div  class="form-table-wrapper">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Company Name</strong>
                                    </td>
                                    <td>
                                        {{payload.billing && payload.billing.company_name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Email Address.</strong>
                                    </td>
                                    <td>
                                        {{payload.billing && payload.billing.email_address}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="radio-table-container"  v-else>
                        <v-radio-group
                            v-model="payload.billing_id"
                            row>
                            <div class="radio-wrapper-table" v-for="(item, index) in billing_info" :key="index">
                                <div class="d-flex justify-lg-space-between mb-2">
                                    <v-radio
                                        :value="item.id"
                                    ></v-radio>
                                    <div class="actions" v-if="!user.is_admin">
                                        <v-btn icon @click="handleEditBillingAddress(item)">
                                            <v-icon size="20" color="#000">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="handleDeleteBillingAddress(item)">
                                            <v-icon size="20" color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="hard-copy-table">
                                    <table class="table table-bordered">
                                        <tbody >
                                            <tr>
                                                <td>Company Name</td>
                                                <td><strong>{{item.company_name}}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Email Address.  </td>
                                                <td><strong>{{item.email_address}}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </v-radio-group>
                </div>

            </div>
            <div class="mt-10">
                <v-row>
                    <v-col cols="12" sm="2">
                        <div class="d-flex flex-column container-footer">
                            <!-- <v-btn color="primary" v-if="handleAllowSave()" @click="submitRequest" :loading="submitLoading"> -->
                            <template v-if="(payload.insurance_status == 1 && payload.user_id == user.id) || !payload.id">
                                <v-btn color="primary" @click="submitRequest(2)" :loading="submitLoading">
                                        Submit
                                </v-btn>
                                <v-btn color="secondary" @click="handleSaveDraft" :loading="submitLoading">
                                        Draft
                                </v-btn>
                            </template>
                            <template  v-else-if="payload.user_id == user.id && payload.id">
                                <v-btn color="primary" @click="submitRequest(payload.insurance_status)" :loading="submitLoading">
                                        Submit
                                </v-btn>
                                <v-btn color="secondary" @click="handleBack">
                                        back
                                </v-btn>
                            </template>

                            <template v-else-if="user.is_admin">
                                <v-btn color="primary" @click="submitRequest(payload.insurance_status)" :loading="submitLoading">
                                        Submit
                                </v-btn>
                                <v-btn color="secondary" @click="handleBack">
                                        back
                                </v-btn>
                            </template>
                            <template v-else>
                                <v-btn color="secondary" @click="handleBack">
                                        back
                                </v-btn>
                            </template>
                           
                        </div>
                    </v-col>
                    <v-col cols="12" sm="10">
                        <div class="d-flex" style="gap:30px">
                            <div class="d-flex flex-column flex-grow-1">
                                <label>Insurance Status</label>
                                <v-select
                                class="mt-2"
                                :items="insurance_status"
                                v-model="payload.insurance_status"
                                text="text"
                                id="value"
                                label="Solo
                                outlined field"
                                solo
                                outlined
                                :readonly="user.is_admin ? false : true"
                                dense
                                ></v-select>
                            </div>
                            <div class="flex-grow-1">
                                <div class="d-flex flex-column flex-grow-1">
                                    <label>Payment Status</label>
                                    <v-select
                                    :readonly="user.is_admin ? false : true"
                                    class="mt-2"
                                    :items="payment_status"
                                    v-model="payload.payment_status"
                                    text="text"
                                    id="value"
                                    label="Solo
                                    outlined field"
                                    solo
                                    outlined
                                    dense
                                    ></v-select>
                                </div>
                                <input
                                    type="file"
                                    ref="file_input"
                                    class="d-none"
                                    accept="image/*, application/pdf"
                                    @change="getFiles"
                                />
                                <div class="d-flex flex-column flex-grow-1">
                                    <div class="d-flex align-center" style="justify-content: space-between;">
                                        <label>Proof of Payment by AETOS</label>
                                            <template v-if="payload.aetos_proof_of_payment.id && user.is_admin">
                                                <template v-if="payload.requests.length ">
                                                    <v-tooltip left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div class="file-wrapper mr-5" v-bind="attrs" v-on="on" style="cursor: pointer;" @click="show_request = true">
                                                                <v-badge 
                                                                    color="red"
                                                                    :content="payload.requests.length ? payload.requests.length : '0'"
                                                                    >
                                                                </v-badge>
                                                            </div>
                                                        </template>
                                                        <span>Related requests</span>
                                                    </v-tooltip>
                                                </template>
                                                <template v-else>
                                                    <v-btn icon>
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div class="file-wrapper" v-bind="attrs" v-on="on" style="cursor: pointer;" @click="show_request = true">
                                                                    <v-icon>mdi-plus-circle</v-icon>
                                                                </div>
                                                            </template>
                                                            <span>Add to related requests</span>
                                                        </v-tooltip>
                                                    </v-btn>
                                                </template>
                                            </template>
                                    </div>
                                    <v-text-field
                                        id="in-charge"
                                        v-model="payload.aetos_proof_of_payment.file_name"
                                        dense
                                        solo
                                        outlined
                                        readonly
                                      
                                    >
                                    <template v-slot:append v-if="payload.aetos_proof_of_payment.id">
                                        <v-icon size="20" @click="showFile">mdi-download</v-icon>
                                        <v-icon size="20" @click="deleteProofAttachment" color="red" v-if="user.is_admin">mdi-delete</v-icon>
                                    </template>
                                    </v-text-field>
                                    <div v-if="user.is_admin">
                                        <v-btn color="primary" @click="handleSelectFile">
                                            <v-icon>mdi-plus</v-icon>
                                            Upload
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <BillingAddress :open_modal="billing_modal" :selected_item = "item_billing_selected" @handleFetchBilling = "getBillingInfo(user.id)" @handleModalHide = "billing_modal = false, item_billing_selected = []"/>
        <ProcuringEntity :open_modal="procuring_modal" :edit_procuring="edit_procuring" @handleFetchProcuring = "getProcuringEntityList()" @handleModalHide = "procuring_modal = false, edit_procuring = null"/>
        <MailingAddress :open_modal="mailing_modal" :item_mailing_selected="item_mailing_selected"  @handleFetchMailing = "getMailingAddress(user.id)" @handleModalHide = "mailing_modal = false, item_mailing_selected = []"/>
        <Attachment :insurance_status="payload.insurance_status" :file_type_upload="file_type_upload" :open_modal="attachment_modal" @handleSubmitFiles="handleCatchFiles"  @handleModalHide = "attachment_modal = false"/>
        <AttachmentDownloadLogs :open_modal="attachment_logs_modal" :file="attachment_selected" :download_logs="download_logs"  @handleModalHide = "attachment_logs_modal = false"/>
        <RequestRelated 
            :open_modal="related_request_modal" 
            :request_id="payload.id" 
            :company_id="user.default_user_company.company_id" 
            :billing_id ="payload.billing_id"  
            :total_amount ="payload.total_amount"  
            @handleModalHide = "related_request_modal = false"
            @handleAddRelatedRequest = "handleAddRelatedRequest"
        />
        <Company 
            :open_modal="company_modal" 
            :item_selected="item_company_selected" 
            @handleModalHide = "company_modal = false, item_company_selected=null"
            @handleCompanyAdded="company_modal = false, getCompany()"
        />
        <Request 
            :open_modal="show_request" 
            :requests_selected="payload.requests" 
            :attachment_id ="payload.aetos_proof_of_payment.id != undefined ? payload.aetos_proof_of_payment.id : null"  
            @handleModalHide = "show_request = false"
            @handleSaveSelected = "handleSaveSelected"
        />
    </div>
</template>

<script>
  import { payment_status } from  '@/helpers/payment_status';
  import { insurance_status as insurance_statuses } from  '@/helpers/insurance_status';
  import { descriptions } from  '@/helpers/description';
  import BillingAddress from  '../modals/billing_address.vue';
  import ProcuringEntity from  '../modals/procuring_entity.vue';
  import MailingAddress from  '../modals/mailing_address.vue';
  import RequestRelated from  '../modals/Related_request.vue';
  import Attachment from  '../modals/Attachment_file.vue';
  import AttachmentDownloadLogs from  '../modals/Attachment_download_logs.vue';
  import Company from  '../modals/Company.vue';
  import Request from  '../modals/Requests.vue';
  import moment from 'moment';

  export default {
    components:{
        BillingAddress,
        ProcuringEntity,
        MailingAddress,
        Attachment,
        AttachmentDownloadLogs,
        RequestRelated,
        Request,
        Company,
    },
    data () {
      return {
        files_required : null,
        file_type_upload : null,
        other_validity : null,
        valid : true,
        start_date : null,
        date_needed : null,
        show_request : false,
        menuFrom : false,
        dateNeeded : false,
        submitLoading : false,
        attachment_selected : null,
        download_logs : null,
        edit_procuring : null,
        showLoading : false,
        billing_modal : false,
        procuring_modal : false,
        selected_billing : null,
        selected_mail : null,
        mailing_modal : false,
        attachment_modal : false,
        company_modal : false,
        attachment_logs_modal : false,
        related_request_modal : false,
        payment_status : payment_status,
        insurance_status : insurance_statuses,
        item_mailing_selected : null,
        item_billing_selected : null,
        item_company_selected : null,
        procuring_entity_list : [],
        company_list : [],
        mailing_address : [],
        billing_info : [],
        user_incharge : [],
        search:null,
        user : [],
        showeducationmodal:false,
        showeligibilitymodal:false,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        certification: "1",
        procuring_address : '',
        attachments_to_remove : [],
        fileRequire: ["image", "application"],
        payload:{
            user_id: this.$store.getters.user.user.id, 
            role: this.$store.getters.user.default_user_company.role, 
            in_charge_id: this.$store.getters.user.user.id, 
            procuring_entity_id: null, 
            project_name: null, 
            noa_amount: null, 
            insured_amount: null, 
            net_premium: null, 
            remarks: null, 
            policy_no: null, 
            ic_certification_needed: 0, 
            hard_copy: 1, 
            mail_address_id: null, 
            billing_id: null, 
            insurance_status: 1, 
            payment_status: 1, 
            company_id: null, 
            start_date: null, 
            date_needed: null, 
            open_date: false, 
            validity: null, 
            product_classification: null, 
            has_related_request: false, 
            total_amount: 0, 
            for_aetos_files : [],
            for_client_files : [],
            related_request : [],
            requests : [],
            aetos_proof_of_payment : {
                file_name: null, 
                file_path: null, 
                file_size: null, 
                file_patj: null, 
            }
        },
        headers: [
          {
            text: 'Date and Time',
            align: 'start',
            sortable: false,
            value: 'created_at',
          },
          { 
            text: 'Description', 
            align: 'start',
            sortable: false,
            value: 'description' 
          },
          { 
            text: 'Attachment', 
            align: 'start',
            sortable: false,
            value: 'attachment'                    
        },
        ],
        validity: [
            {value: '1 year', text: '1 year' },
            {value: 'Valid until the final acceptance issued', text: 'Valid until the final acceptance issued' },
            {value: 'Other', text: 'Other' },
        ],
      }                           
    },
    // created(){
    //     this.payment_status = payment_status;
    //     this.insurance_status = insurance_status;
    // },
    created(){
      this.user = this.$store.getters.user.user;
      if(this.$route.params.id){
            this.$notiflix.Loading.arrows();
       }
    },
    computed: {
        noa_amount: {
            get() {
                return this.formatNumber(this.payload.noa_amount);
            },
            set(value) {
                this.payload.noa_amount = value.replace(/,/g, '');
            }
        },
        insured_amount: {
            get() {
                return this.formatNumber(this.payload.insured_amount);
            },
            set(value) {
                this.payload.insured_amount = value.replace(/,/g, '');
            }
        },
        net_premium: {
            get() {
                return this.formatNumber(this.payload.net_premium);
            },
            set(value) {
                this.payload.net_premium = value.replace(/,/g, '');
            }
        },
    },
    mounted(){
       this.getUserInchargeList();
       this.getProcuringEntityList();
       this.getCompany();
       this.filterInsuranceStatus(this.payload.hard_copy)
       if(this.$route.params.id){
            this.getRequest();
       }
       else{
        this.getMailingAddress(this.user.id);
        this.getBillingInfo(this.user.id);
       }
       
    },
    methods:{
        handleSaveSelected(selected) {
         this.payload.requests = selected;
         this.show_request = false;
        },
        deleteProofAttachment() {
            this.$notiflix.Confirm.show(
                'Attachment',
                'Are you sure you want to remove this attachment for this request?',
                'Yes',
                'No',
                () => {
                  this.removeProofOfPayment();
                },
            );
        },
        removeProofOfPayment() {
            this.$notiflix.Loading.arrows('Removing attachment...');
            var params = {
                id: this.$route.params.id,
                attachment_id: this.payload.aetos_proof_of_payment.id,
            }
            this.axios.post(`/request/remove-proof-of-payment`,params).then((res)=>{
                this.$awn.success(`Removed Successfully`);
                this.payload.aetos_proof_of_payment = {
                    file_name: null, 
                    file_path: null, 
                    file_size: null, 
                    file_patj: null, 
                };
                this.payload.requests = [];
            }).catch((err)=>{
                    console.log(err,'err')
            }).finally((err)=>{
                this.$notiflix.Loading.remove();
            })
        },
        showFile(item){
            this.$notiflix.Loading.arrows('Downloading...');
            this.axios.post('/resources/download',{...this.payload.aetos_proof_of_payment, user_id:this.user.id},{
				responseType: 'blob',
			}).then((response)=>{
                if(response.status == 200){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.payload.aetos_proof_of_payment.file_name); 
                    document.body.appendChild(link);
                    link.click();
                    this.getRequest();
			}
           }).catch((err)=>{
                console.log(err,'err')
           })
        },
        handleSelectFile() {
            this.$refs.file_input.click();
        },
        getFiles(event) {
            var input = event.target;
            if (input.files) {
                Object.values(input.files).forEach(function (dataFile) {
                var split = dataFile["type"].split("/");
                if (this.fileRequire.includes(split[0])) {
                    if (dataFile["size"] <= 15000000) {
                        var data = {
                            file_type: dataFile["type"],
                            file_size: dataFile["size"],
                            file_name: dataFile["name"],
                        };
                        var reader = new FileReader();
                        reader.onload = (e) => {
                            data["file_path"] = e.target.result;
                            this.payload.aetos_proof_of_payment = data;
                            this.handleAttachToOtherRequest();
                        };
                        reader.readAsDataURL(dataFile);
                    } else {
                        this.$awn.error(`The file ${dataFile['name']} too large for the 15MB limit`)
                    }
                } else {
                    this.$awn.error(`Unsuforted File ${dataFile['name']}`)
                }
                }, this);
                // }
            }
        },
        allowedDates(date) {
            const today = moment(moment()).format('YYYY-MM-DD');
            const selectedDate = moment(date).format('YYYY-MM-DD');
            return selectedDate >= today;
        },
        isNumber(e){
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace'];
            const keyPressed = e.key;

            if (!keysAllowed.includes(keyPressed)) {
                e.preventDefault()
            }
        },
        formatNumber(value) {
        // Format the number with commas
            if (typeof value !== 'string') {
                value = String(value || ''); // Ensure value is a string
            }
            const [integerPart, decimalPart] = value.split('.');
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

        },
        handleAttachmentToRemove(item, type){
            this.attachments_to_remove = [...this.attachments_to_remove , item]
            this.handleRemoveAttachment(item, type)
        },
        handleSaveDraft(type){
            this.submitRequest(1)
        },
        handleAddRelatedRequest(selected){
           this.related_request_modal = false
           this.payload.related_request = selected;
        },
        handleEditCompanyList(event, item){
          event.stopPropagation();
          this.item_company_selected = item
          this.company_modal = true
        },
        handleDeleteCompanyList(event, item){
            event.stopPropagation();
            if(item.is_used){
                return this.$notiflix.Report.failure(
                    'Warning',
                    '<h5>You can’t delete this company because it is currently in use.</h5>',
                    'Okay',
                );
            }
            else{
                this.$notiflix.Confirm.show(
                    'Delete',
                    'Are you sure, you want to delete this billing?',
                    'Yes',
                    'No',
                    () => {
                        this.deleteCompany(item)
                    },
                );
            }
        },
        handleAttachToOtherRequest(){
            this.$notiflix.Confirm.show(
                'Attachment',
                'Would you like to attach this Proof of payment by AETOS to other related requests?',
                'Yes',
                'No',
                () => {
                   this.show_request = true
                },
            );
        },

        deleteCompany(item){
            this.$notiflix.Loading.arrows();
            this.submitLoading = true;
            this.axios.delete(`/company/delete/${item.value}`).then((res)=>{
                this.$awn.success(`Deleted Successfully`);
                this.getCompany();
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.submitLoading = false;
            })
        },

        handleShowUploadFile(type){
            this.file_type_upload = type;
            this.attachment_modal = true
         },
        handleValidation(name){
             return [
                 v => !!v || `${name} is required`,
             ];
         },
        handleShowAddAttachemt(){
            if(this.user.default_user_company.role == 'SA'){
                return false;
            }
            if(this.payload.id === undefined || this.payload.user_id == this.user.id || this.payload.insurance_status == 1 ){
                return true
            }
            else{
                return false
            }
        },
        handleshowDownloadLogs(item){
           this.attachment_selected = item;
           this.$notiflix.Loading.arrows();
           this.axios.get(`/resource/download-logs/${item.id}`).then((res)=>{
               if(res.status == 200){
                    this.download_logs = res.data.data
                    this.attachment_logs_modal = true;
               }
           }).catch((err)=>{
                console.log(err,'err')
           }).finally((err)=>{
            this.$notiflix.Loading.remove();
           })
        },
        handleBack(){
            this.$router.back();
        },
        handleDeleteList(event, item){
            event.stopPropagation();
            if(item.is_used){
                // return this.$awn.modal(`<b>You can’t delete this entity because it is currently in use</b>`)
                this.$notiflix.Report.warning(
                    'Warning',
                    '<h5>You can’t delete this entity because it is currently in use.</h5>',
                    'Okay',
                );
            }
            else{
                this.$notiflix.Confirm.show(
                    'Delete',
                    'Do you want to delete this item?',
                    'Yes',
                    'No',
                    () => {
                        this.handleDeleteProcuring(item.value)
                    },
                );
            }
           
        },
        handleEditList(event, item){
            event.stopPropagation();
            var data = {
                name : item.text,
                id : item.value,
                address : item.address,
            }
            this.edit_procuring = data;
            this.procuring_modal = true;
        },
        handleDontAllowEdit (){
           if(this.user.is_admin && this.payload.insurance_status == 2){
               return false
           }

           if(this.payload.user_id === this.user.id  && this.payload.insurance_status == 1){
               return false
           }
           else if(!this.payload.id){
        //    else if(this.payload.insurance_status != 1 && this.payload.id !== undefined){
                return false;
           }
           else{
             return true
           }
        },
        handleAllowSave (){
           if(this.user.default_user_company.role == 'SA'){
                return true;
           }
           if(this.payload.user_id === this.user.id && this.payload.insurance_status == 1){
                return true
           }
           else{
             return false
           }
        },
        closedialog(){
            this.showeducationmodal = false;
            this.showeligibilitymodal = false;
        },
        getProcuringAddress(){
            const procuring_data = this.procuring_entity_list.find(item=>item === this.payload.procuring_entity_id);
            console.log(procuring_data,'procuring_data')
        },
        submitRequest(insurance_status){
        
            if(!this.$refs.form.validate()){
                return this.$awn.alert('Please check the required field')
            }

            if(!this.user.is_admin){
                this.payload.insurance_status = insurance_status;
            }

            if(this.payload.hard_copy == 1 && !this.payload.mail_address_id){
                this.files_required = 'hard_copy';
                return this.$awn.alert('Mail address required');
            }
            
            
            if(this.user.is_admin && this.payload.insurance_status > 3  && this.payload.insurance_status < 7){
                if(this.payload.for_client_files.length == 0){
                    this.files_required = 'for_client';
                    return this.$awn.alert('For Client files required');
                }
            }

        
            // if(this.payload.for_aetos_files.length == 0 && !this.user.is_admin){
            //     this.files_required = 'for_aetos';
            //     return this.$awn.alert('For Aetos files required');
            // }

            if(!this.payload.billing_id){
                this.files_required = 'billing';
                return this.$awn.alert('Billing details required');
            }

            this.submitLoading = true;
            this.payload.attachment_to_remove = this.attachments_to_remove;
            if(this.payload.validity == 'Other'){
                this.payload.validity = this.other_validity;
            }
            if(this.payload.requests.length){
                const ids = this.payload.requests.map((item)=>{
                    return {
                        id:item.id
                    }
                })
                this.payload.requests = ids;
            }
            this.$awn.asyncBlock(
                this.axios.post('/request',this.payload),
                null
            ).then(resp =>
                this.$awn.success(`${this.payload.id ? 'Updated': 'Added'} successfully`),
                this.$router.push('/'),
            ).finally(
                resp =>  this.submitLoading = false,
            );
        },

        handleDeleteMailAddress(item){
            if(item.request.length){
                return this.$notiflix.Report.failure(
                    'Warning',
                    '<h5>You can’t delete this mailing because it is currently in use.</h5>',
                    'Okay',
                );
            }
            else{
                this.$notiflix.Confirm.show(
                    'Delete',
                    'Are you sure, you want to delete this billing?',
                    'Yes',
                    'No',
                    () => {
                        this.deleteMailing(item)
                    },
                );
            }
           
        },

        deleteMailing(item){
            this.$notiflix.Loading.arrows();
            this.axios.delete(`/mailing/${item.id}`).then((res)=>{
                this.$awn.success(`Deleted Successfully`);
                this.getMailingAddress(this.user.id)
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.$notiflix.Loading.remove();
            })
        },

        handleDownloadAttachment(item){
            this.$notiflix.Loading.arrows();
            this.axios.post('/resources/download',{...item, user_id:this.user.id},{
				responseType: 'blob',
			}).then((response)=>{
                if(response.status == 200){
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.file_name); 
                    document.body.appendChild(link);
                    link.click();
                    this.getRequest();
			}
           }).catch((err)=>{
                console.log(err,'err')
           })
        },

        handleEditMailAddress(item){
           this.item_mailing_selected = item;
           this.mailing_modal = true
        },

        handleDeleteBillingAddress(item){
            this.$notiflix.Confirm.show(
                'Delete Billing Details',
                'Are you sure you want to delete this billing lists?',
                'Yes',
                'No',
                () => {
                    this.handleDeleteBillingDetails(item)
                },
            );
        },
        handleDeleteBillingDetails(item){
            this.axios.delete(`/billing/${item.id}`).then((res)=>{
                if(res){
                    this.getBillingInfo()
                }
            }).catch((error)=>{
                console.log(error,'error')
            }).finally((fin)=>{
                this.$awn.success(`Deleted Successfully`);
            })
        },
        handleEditBillingAddress(item){
           this.item_billing_selected = item;
           this.billing_modal = true
        },
        handleCatchFiles(files){
           this.attachment_modal = false;
           this.files_required = '';
           if(files.description_id  == 5 && this.payload.has_related_request){
                this.payload.payment_status = 2;
                this.$notiflix.Confirm.show(
                    'Related Request',
                    'Would you like to attach the proof of payment to the related request?',
                    'Yes',
                    'No',
                    () => {
                       this.related_request_modal = true
                    },
                );
           }

           if(files.description_id  == 8){
                this.payload.insurance_status = 3;
           }

           if(files.description_id  == 6){
                this.payload.insurance_status = 9;
           }
          
           if(files.description_id  == 9){
                this.payload.insurance_status = 10;
           }
          

           if(!this.user.is_admin){
                const desc = descriptions.find(descript => descript.value === files.description_id);
                const attachment_list = files.files.map((item)=>{
                    return {
                        id : null,
                        created_at: this.$utils.changeFormat(moment()),
                        description: files.description_id == 7 ? files.other :  desc.text,
                        description_id: files.description_id,   
                        file_name: item.name,     
                        file_type: item.type,     
                        file_size: item.size,     
                        file_path: item.file,     
                    };
                })
                this.payload.for_aetos_files = [...this.payload.for_aetos_files, ...attachment_list];

           }
           else{
               const desc = descriptions.find(descript => descript.value === files.description_id);
                const attachment_list = files.files.map((item)=>{
                    return {
                        id : null,
                        created_at: this.$utils.changeFormat(moment()),
                        description: files.description_id == 7 ? files.other :  desc.text,
                        description_id: files.description_id,   
                        file_name: item.name, 
                        file_type: item.type,        
                        file_size: item.size,     
                        file_path: item.file, 
                    };
                })

                this.payload.for_client_files = [...this.payload.for_client_files, ...attachment_list];
           }
           
        },
        handleRemoveAttachment(file, type){
            var index = this.payload[type].findIndex(item => item.file_name === file.file_name && !item.id);
            this.payload[type].splice(index,1);
            const for_validation = this.payload.for_aetos_files.find(item=>item.description == 'Proof of Payment');
            if(!for_validation){
                this.payload.payment_status = 1
            }
        },
        getProcuringEntityList(){
           this.axios.get('/procuring').then((res)=>{
                this.procuring_entity_list = res.data.data.map((item)=>{
                    return {
                        value : item.id,
                        text : item.name,
                        address : item.address,
                        is_used : item.request ? true : false,
                    }
                })
           }).catch((err)=>{
                console.log(err,'err')
           })
        },
        getCompany(){
           this.axios.get('/company/get-company').then((res)=>{
                this.company_list = res.data.data.map((item)=>{
                    return {
                        value : item.id,
                        text : item.name,
                        is_used : item.request.length ? true : false,
                    }
                })
           }).catch((err)=>{
                console.log(err,'err')
           }).finally((err)=>{
                
           })
        },
        
        getMailingAddress(id){
           this.axios.get(`/mailing?user_id=${id}`).then((res)=>{
                this.mailing_address = res.data.data;
           }).catch((err)=>{
                console.log(err,'err')
           })
        },
        getBillingInfo(id = null){
           this.axios.get(`/billing?company_id=${this.user.default_user_company.company_id}`).then((res)=>{
                const default_billing = res.data.data.find(item => item.pre_selected);
                if(default_billing){
                    this.payload.billing_id = default_billing.id
                }
                this.billing_info = res.data.data.slice().sort((a, b) => b.pre_selected - a.pre_selected);
           }).catch((err)=>{
                console.log(err,'err')
           })
        },
        getUserInchargeList(){
           this.axios.post(`/user/user-incharge`,{
                company_id :  this.user.default_user_company.company_id,
                role :  this.user.default_user_company.role,
                user_id :  this.user.default_user_company.user_id,
                id :  this.user.default_user_company.id,
           }).then((res)=>{
                this.user_incharge = res.data.data.map((item)=>{
                    return {
                        value : item.user.id,
                        text : item.user.first_name + ' ' + item.user.last_name,
                    }
                })
           }).catch((err)=>{
                console.log(err,'err')
           })
        },
        getRequest(){
          this.$notiflix.Loading.arrows();

           this.axios.get(`/request/${this.$route.params.id}`).then((res)=>{
            this.payload.id = res.data.data.id;
            this.fillPayloadParams(res.data.data);
            this.payload.mailing = res.data.data.mailing;
            this.payload.billing = res.data.data.billing;
            this.payload.role = this.user.default_user_company.role;
            const attachment_files = res.data.data.request_attachment;
            this.attachmentCatch(attachment_files);
            this.attachmentAetosProofOfPayment(res.data.data.aetos_proof_of_payment);
            this.getMailingAddress(res.data.data.user_id)
            this.getBillingInfo(res.data.data.user_id)
            this.showLoading = false;  
           }).catch((err)=>{
                console.log(err,'err')
           }).finally(()=>{
            this.$notiflix.Loading.remove();
           })
        },

        fillPayloadParams(result){
            Object.entries(this.payload).forEach(([key]) => {
                if(key == 'validity'){
                    const validity = this.validity.find(item => item.value == result[key]);
                    if(validity){
                        this.payload[key] = result[key]; 
                    }
                    else{
                        this.payload.validity = 'Other';
                        this.other_validity =  result[key];
                    }
                }
                else{
                    this.payload[key] = result[key];
                }
            });
            this.payload.requests = [];
        },

        attachmentCatch(attachment_files){
            this.payload.for_aetos_files = [];
            this.payload.for_client_files = [];
            this.payload.aetos_proof_of_payment = {
                file_name: null, 
                file_path: null, 
                file_size: null, 
                file_patj: null, 
            };
            var for_aetos_files = [];
            var for_client_files = [];
            if(attachment_files.length){
                attachment_files.map((item)=>{
                    const desc = descriptions.find(descript => descript.value === item.description_id);
                    var data =  {
                        request_id : item.id,
                        id : item.attachment.id,
                        created_at: this.$utils.changeFormat(item.created_at),
                        // created_at: moment(item.created_at).format('dddd'),
                        description: item.description,   
                        // description: desc.item,   
                        description_id: item.description_id,   
                        file_name: item.attachment.file_name, 
                        file_type: item.attachment.file_type,        
                        file_size: item.attachment.file_size,     
                        file_path: item.attachment.file_path, 
                        total_download: item.attachment.total_download, 
                    };
                    if(item.type == 1){
                        for_aetos_files.push(data)
                    }
                    else{
                        for_client_files.push(data)
                    }
                });
            }


            this.payload.for_aetos_files = [...for_aetos_files];
            this.payload.for_client_files = [...for_client_files];
        },
        attachmentAetosProofOfPayment(proof_of_payment){
            this.payload.aetos_proof_of_payment = {
                file_name: null, 
                file_path: null, 
                file_size: null, 
                file_patj: null, 
            };
            if(proof_of_payment && proof_of_payment.attachment){
                this.payload.aetos_proof_of_payment = proof_of_payment.attachment;
            }
        },

        handleDeleteProcuring(id){
           this.axios.delete(`/procuring/${id}`).then((res)=>{
            if(res.status == 200){
                this.$awn.success('Entity Deleted Successfully')
                this.getProcuringEntityList();
            }
           }).catch((err)=>{
                console.log(err,'err')
           })
    },
        filterInsuranceStatus(value){
            if(!value){
                const insurance_status = insurance_statuses.filter(status => {
                    return status.value !== 5 && status.value !== 6;
                });
                this.insurance_status = insurance_status;
           }
           else{
                const insurance_status = insurance_statuses.filter(status => {
                    return status.value !== 4;
                });

                this.insurance_status = insurance_status;
           }
        },
    },
    watch: {
        'payload.procuring_entity_id'(newValue) {
            const procuring = this.procuring_entity_list.find(item=>item.value === newValue);
            if(procuring){
                this.procuring_address = procuring.address;
            }
        },
        'procuring_entity_list'(newValue) {
            const procuring = newValue.find(item=>item.value === this.payload.procuring_entity_id);
            if(procuring){
                this.procuring_address = procuring.address;
            }
        },
        'payload.noa_amount'(newValue) {
            this.payload.insured_amount = newValue * .30;
        },

        'payload.mail_address_id'(newValue) {
           if(newValue){
            this.files_required = ''
           }
        },
        'payload.billing_id'(newValue) {

           if(newValue){
            this.files_required = ''
           }
        },
        'payload.hard_copy'(newValue) {
          this.filterInsuranceStatus(newValue)
        },
        'payload.open_date'(newValue) {
          if(newValue){
            this.payload.start_date = null
          }
          else{
            if(this.start_date){
                this.payload.start_date = this.start_date
            }
          }
        },
        'payload.start_date'(newValue) {
          if(newValue){
            this.start_date = newValue
          }
        },
    }
  }
</script>


<style scoped>
    .form-table-wrapper{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    td, th {
        border: 1px solid #ddd;
        padding: 8px;
        width: fit-content;
        font-size: 14px !important;
    }
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    th {
        background-color: #4CAF50;
        color: white;
    }
    .fields_required{
        border: solid 3px red;
    }
</style>
<style>
 .v-input--switch .v-input__control .v-input__slot {
        width: fit-content !important;
    }
</style>